import type { AxiosError, AxiosResponse } from "axios";

export const onFulfilled = (response: AxiosResponse): AxiosResponse => response;

export const onRejected = (error: AxiosError): Promise<never> => {
  // If not an API v3 route, exit early by returning the error.
  // The axios request that instigated this should finish.

  const url = (error.request as { responseURL?: string }).responseURL;

  if (!url) {
    // This should be an error
    return Promise.reject(error);
  }

  if (!new URL(url).pathname.startsWith("/v3/")) {
    // This should be an error
    return Promise.reject(error);
  }

  // If not a status code 422 response or has no errors array, exit early by returning the error.
  // The axios request that instigated this should finish.

  if (error.response?.status !== 422 || !Array.isArray(error.response.data)) {
    // This should be an error
    return Promise.reject(error);
  }

  // This should be an error
  // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
  return Promise.reject(error.response.data);
};
