import { useAuthStore } from "@/vue/apps/shared/store/authStore.ts";

export default (request) => {
  const authStore = useAuthStore();
  // If not an API v3 route, exit early by returning the request.
  if (!new URL(request.url).pathname.startsWith("/v3/")) {
    return request;
  }

  // If there is no access token in the authentication module, exit early by returning the request.
  if (!authStore.hasAccessToken) {
    return request;
  }

  // Otherwise, retrieve the token and use it to add an Authorization header to the request.
  const token = authStore.accessToken;
  request.headers.Authorization = `Bearer ${token}`;

  return request;
};
