import axios from "axios";

import type { LocaleIso, LocaleMorpheus } from "@solvari/translations";
import { getEnv } from "@solvari/utils";

export type AuthUser = {
  id: number;
  permissions?: string[];
  pro: {
    locale: LocaleIso | LocaleMorpheus;
    subscription: { permissions: string[] } | null;
    uuid: string;
  } | null;
  roles?: string[];
};

export async function fetchUser(accessToken: string): Promise<AuthUser> {
  const url = `${getEnv().network.api}/v3/my/user`;
  const options: { headers?: { Authorization: string } } = {};

  if (accessToken) {
    options.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const response = await axios.get<{ data: AuthUser }>(url, options);

  return response.data.data;
}
