import { computedAsync } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed } from "vue";

import { fetchUser } from "@/vue/apps/shared/api/auth.api.ts";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    oauthAccessToken?: string;
  }
}

const useAuthStore = defineStore("auth", () => {
  const accessToken = window.oauthAccessToken ?? "";

  const user = computedAsync(() => fetchUser(accessToken), null);

  const userRoles = computed(() =>
    user.value?.roles ? new Set(user.value.roles) : new Set<string>(),
  );
  const userPermissions = computed(() => {
    const permissions = new Set<string>();
    user.value?.permissions?.forEach(permissions.add);
    user.value?.pro?.subscription?.permissions.forEach(permissions.add);
    return permissions;
  });

  return {
    accessToken,
    user,
    userId: computed(() => user.value?.id ?? null),
    customerUuid: computed(() => user.value?.pro?.uuid ?? null),
    locale: computed(() => user.value?.pro?.locale ?? null),
    hasAccessToken: !!accessToken,
    userRoles,
    userPermissions,
    isAny: (roles: string[]) => roles.some((role) => userRoles.value.has(role)),
    isAll: (roles: string[]) =>
      roles.every((role) => userRoles.value.has(role)),
  };
});

export { useAuthStore };
